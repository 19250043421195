<template>
  <div
    class="etc-button white"
    style="width: 100%;"
  >
    <div

      style="color: #666;"
    >
      <template v-if="selectedItems.length > 0">
        <div class=" font__13">
          {{ selectedItems.length }} {{ '개의 매출이 선택되었습니다' | translate }}
        </div>
      </template>
      <template v-else>
        <div class="font__13">
          {{ '기타수납할 매출을 선택해 주세요' | translate }}
        </div>
      </template>
      <div class="font__13">
        {{ '잔액 합계' | translate }} : {{ sumBalance | currency }}{{ '원' | translate }}
      </div>
    </div>
    <v-text-field
      v-model="etcForm.amount"
      class="pt-0 px-2 mt-0"
      full-width
      height="50px"
      type="number"
      :placeholder="'기타수납할 금액을 입력하세요' | translate"
      hide-details
      background-color="white"
    />
    <div>
      <mobile-select
        v-model="etcForm.paymentMethod"
        label="입금수단"
        :items="selectItems.paymentMethodList"
      />
    </div>
    <div>
      <mobile-select
        v-if="etcForm.paymentMethod === 'B'"
        v-model="etcForm.bankName"
        label="관련통장"
        :items="selectItems.bankList"
      />
    </div>
    <v-btn
      min-width="100%"
      color="info"
      dark
      class="ma-0"
      style="margin-top: auto"
      @click="onClickRequest"
    >
      {{ "기타수납처리" | translate }}
    </v-btn>
  </div>
</template>

<script>
  import now from "@/assets/plugins/mps-common/time/now";
  import {PATH} from "@/constants";

    export default {
        name: "EtcButton",
        props: {
            selectedItems: {
                type: Array,
                default: () => [],
            },
          accountId: {
            type: Number,
          },
        },
        data() {
            return {
                selectItems: {
                  bankList: [],
                  paymentMethodList: [
                    {label: '현금', value: 'S'},
                    {label: '계좌이체', value: 'B'},
                    {label: '카드', value: 'C'},
                  ]
                },
                etcForm: {
                  bankName: undefined,
                  accountId: undefined,
                  service: "E", // 기타수납처리
                  method: 'S', // 현금
                  process: "Z", // 입금
                  withdrawalDate: now().toISO(), // 입금일자는 지금으로
                  requestWhen: "N",
                  paymentMethod: 'S', // 현금
                  amount: 0,
                  billingIds: undefined,
                }
            }
        },
        computed: {
            sumBalance() {
                let sum = 0;
                for (let i = 0; i < this.selectedItems.length; i++) {
                    const item = this.selectedItems[i];
                    sum += item.raw.balance;
                }
                return sum;
            },
        },
        watch: {
          'etcForm.paymentMethod'() {
            this.etcForm.method = this.etcForm.paymentMethod
          },
          selectedItems() {
            this.etcForm.billingIds = this.selectedItems.map(item => item._item.billingId)
          }
        },
      created() {
          this.loadBankList()
        this.etcForm.accountId = this.accountId
      },

        methods: {
            onClickRequest() {
                if (!this.etcForm.amount) return this.$alert(this.$translate("기타수납할 금액을 입력해 주세요"));
                if (this.selectedItems.length === 0) return this.$alert(this.$translate("기타수납할 매출을 선택해 주세요"));
                if (this.sumBalance === 0) return this.$alert(this.$translate("기타수납할 잔액이 없습니다"));
                if (this.etcForm.amount > this.sumBalance) this.$alert(this.$translate("기타수납할 금액은 잔액을 초과할 수 없습니다"));

                this.$dialog()
                    .message(this.$currency(this.etcForm.amount) + this.$translate("원을 기타수납처리하시겠습니까?"))
                    .buttonPositive(this.$translate('확인'), () => {
                        this.$emit('click:request', this.etcForm);
                    })
                    .buttonNegative(this.$translate("취소"), () => { /* do nothing */
                    })
                    .build()
                    .show();
            },
          async loadBankList() {
            const result = await this.$request(PATH.OPTIONS_LOAD_CODE_RENTAL_COMPANY_CODES)
                    .setObject({codeType:"PACCT"})
                    .enqueue()
            result.data.map((e) => {
              this.selectItems.bankList.push(
                      {label: e.codeValue, value: e.codeValue}
              )
            })
            if(result.data.length > 0 ) {
              this.etcForm.bankName = this.selectItems.bankList[0].value
              this.etcForm.paymentMethod = 'B'
            }


          },
        }
    }
</script>

<style scoped>
    .etc-button {
        border-top: 1px solid #ddd;
    }

    .v-btn {
        border-radius: 0 !important;
        height: 50px !important;
    }

    .v-input__slot > .v-text-field__slot {
        padding: 0px 0px !important;
    }

    >>> .v-text-field input {
        background-color: #e8e8e8;
        text-align: center !important;
        border: 0;
        padding: 20px 5px;
    }

    >>> .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
        border: 0;
    }

    >>> .v-btn__content {
        font-size: 1.1rem !important;
        font-weight: bold !important;
    }

    >>> .v-btn {
        height: 50px !important;
    }
</style>